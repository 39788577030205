<template>
  <div>
    <MainStepper e1="3" />
    <v-card outlined>
      <v-row class="mx-1">
        <v-col cols="4">
          <span class="text-h6 primary--text">EVALUATION REPORT</span>
        </v-col>
        <v-col cols="8">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="success"
                dark
                class="text-capitalize ml-1 float-right"
                :loading="loading"
                @click="awardSupplier"
                elevation="0"
                v-bind="attrs"
                v-on="on"
              >
                Award
                <v-icon>mdi-checkbox-multiple-marked-circle-outline</v-icon>
              </v-btn>
            </template>
            <span>Award tender to a supplier</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                class="text-capitalize ml-1 float-right"
                :loading="loading"
                @click="goToEvaluationReports"
                elevation="0"
                fab
                x-small
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-file-chart</v-icon>
              </v-btn>
            </template>
            <span>Evaluation reports</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                class="text-capitalize ml-1 float-right"
                :loading="loading"
                @click="goToSupplierSelection"
                elevation="0"
                fab
                x-small
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-format-list-bulleted-square</v-icon>
              </v-btn>
            </template>
            <span>Back to supplier selection</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <div id="printable_content">
        <EvaluationReportHeader />

        <!-- START Report -->
        <v-data-table
          :headers="headers"
          :items="evaluation_report"
          :items-per-page="100"
          class="elevation-1"
        ></v-data-table>
        <!-- END report  -->
        <h3 class="ml-5 mt-5">Key:</h3>
        <v-divider class="my-5"></v-divider>
        <v-data-table
          :headers="headers_bidders"
          :items="evaluation_bidders"
          :items-per-page="100"
          class="elevation-1"
        ></v-data-table>

        <v-simple-table v-if="applicant2">
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title
                class="error--text text--darken-1 justify-center"
              >
                <v-icon class="mr-2 mt-n1" color="error"
                  >mdi-file-document-edit</v-icon
                >Bidder 2
              </v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
            </v-toolbar>

            <v-progress-linear
              indeterminate
              color="primary"
              v-if="loading"
            ></v-progress-linear>
            <v-divider></v-divider>
          </template>

          <template v-slot:default>
            <thead>
              <tr>
                <td>Applicant Name</td>
                <td>
                  <strong>{{ bidder_2.applicant.name }}</strong>
                </td>
                <td></td>
                <td></td>
              </tr>

              <tr>
                <td>Company Name</td>
                <td>
                  <strong>{{ bidder_2.applicant.company.company_name }}</strong>
                </td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>Email</td>
                <td>
                  <strong>{{ bidder_2.applicant.email }}</strong>
                </td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>Phone</td>
                <td>
                  <strong>{{ bidder_2.applicant.phone }}</strong>
                </td>
                <td></td>
                <td></td>
              </tr>

              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>

              <tr>
                <th class="text-left">#</th>
                <th class="text-left">Document Name</th>
                <th class="text-left">Description</th>
                <th class="text-center">Score</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, i) in bidder_2.documents" :key="i">
                <td>{{ i + 1 }}</td>
                <td>{{ item.document.name }}</td>
                <td>{{ item.document.description }}</td>
                <td class="text-center">
                  <template>
                    <v-chip class="ma-2" color="grey" text-color="white">
                      {{ item.score }}
                    </v-chip>
                  </template>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td></td>
                <td></td>
                <td><strong>Total Score</strong></td>
                <td class="text-center">
                  <template>
                    <v-chip class="ma-2" color="green" text-color="white">
                      {{ decimalDigits(bidder_2.total) }}
                    </v-chip>
                  </template>
                </td>
              </tr>
            </tfoot>
          </template>
        </v-simple-table>

        <v-simple-table v-if="applicant3">
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title
                class="error--text text--darken-1 justify-center"
              >
                <v-icon class="mr-2 mt-n1" color="error"
                  >mdi-file-document-edit</v-icon
                >Bidder 3
              </v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
            </v-toolbar>

            <v-progress-linear
              indeterminate
              color="primary"
              v-if="loading"
            ></v-progress-linear>
            <v-divider></v-divider>
          </template>

          <template v-slot:default>
            <thead>
              <tr>
                <td>Applicant Name</td>
                <td>
                  <strong>{{ bidder_3.applicant.name }}</strong>
                </td>
                <td></td>
                <td></td>
              </tr>

              <tr>
                <td>Company Name</td>
                <td>
                  <strong>{{ bidder_3.applicant.company.company_name }}</strong>
                </td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>Email</td>
                <td>
                  <strong>{{ bidder_3.applicant.email }}</strong>
                </td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>Phone</td>
                <td>
                  <strong>{{ bidder_3.applicant.phone }}</strong>
                </td>
                <td></td>
                <td></td>
              </tr>

              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>

              <tr>
                <th class="text-left">#</th>
                <th class="text-left">Document Name</th>
                <th class="text-left">Description</th>
                <th class="text-center">Score</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, i) in bidder_3.documents" :key="i">
                <td>{{ i + 1 }}</td>
                <td>{{ item.document.name }}</td>
                <td>{{ item.document.description }}</td>
                <td class="text-center">
                  <template>
                    <v-chip class="ma-2" color="grey" text-color="white">
                      {{ item.score }}
                    </v-chip>
                  </template>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td></td>
                <td></td>
                <td><strong>Total Score</strong></td>
                <td class="text-center">
                  <template>
                    <v-chip class="ma-2" color="green" text-color="white">
                      {{ decimalDigits(bidder_3.total) }}
                    </v-chip>
                  </template>
                </td>
              </tr>
            </tfoot>
          </template>
        </v-simple-table>
      </div>
    </v-card>

    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      filename="hee hee"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="landscape"
      pdf-content-width="800px"
      @progress="onProgress($event)"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
      ref="html2Pdf"
    >
      <section slot="pdf-content" id="printarea">
        <!-- PDF Content Here -->
      </section>
    </vue-html2pdf>

    <v-dialog v-model="award_dialog" max-width="550px">
      <v-card>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card-title>
            <span class="headline primary--text">Supplier awarding</span>
            <v-spacer></v-spacer>
            <v-btn
              color="error"
              class="text-capitalize mx-1"
              dark
              fab
              x-small
              elevation="0"
              @click="award_dialog = false"
            >
              <v-icon small>mdi-cancel</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider class="my-n2"></v-divider>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="12" md="12" class="my-n2">
                <v-autocomplete
                  v-model="editedItem.contractor_id"
                  :items="evaluation_bidders"
                  label="Select supplier *"
                  :item-text="getBidderName"
                  item-value="id"
                  color="grey"
                  dense
                  outlined
                  class="d-flex align-end"
                  :rules="selectRules"
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" sm="12" md="12" class="my-n2">
                <v-text-field
                  v-model="editedItem.name"
                  label="Reason/Justification *"
                  :rules="rules.required"
                  color="grey"
                  dense
                  outlined
                  class="mb-n3"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12" class="my-n5">
                <v-textarea
                  v-model="editedItem.description"
                  outlined
                  name="input-7-4"
                  label="Details"
                  color="grey"
                  dense
                  :rules="rules.required"
                  class="mb-n3"
                >
                  <template v-slot:label>
                    <span class="input__label"
                      >Document Description
                      <v-icon small color="error" class="mt-n2"
                        >mdi-star-outline</v-icon
                      ></span
                    >
                  </template>
                </v-textarea>
              </v-col>
              <v-col cols="12" class="my-n1">
                <v-file-input
                  v-model="editedItem.attachment_file"
                  color="grey"
                  append-icon="mdi-attachment"
                  prepend-icon=""
                  label="Support Document"
                  outlined
                  dense
                  @change="handleFileUpload"
                >
                </v-file-input>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="success"
              dark
              class="text-capitalize mx-1"
              :loading="loading"
              @click="awardAndContinueToApproval"
            >
              Award <v-icon>mdi-content-save-outline</v-icon>
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import EvaluationReportHeader from "@/components/EvaluationReportHeader.vue";
import VueHtml2pdf from "vue-html2pdf";
import TenderService from "../../services/tender.service";
import MainStepper from "../../components/MainStepper.vue";

export default {
  data: () => ({
    dialog: false,
    valid: true,
    rules: {
      select: [(v) => !!v || "An item should be selected"],
      select2: [(v) => v.length > 0 || "Atleast one item should be selected"],
      required: [
        (v) => !!v || "Field is required",
        (v) => (v && v.length >= 3) || "Min 3 characters",
      ],
    },
    selectRules: [(v) => !!v || "Select is required"],
    award_dialog: false,
    loading: true,
    evaluation_report: [],
    evaluation_bidders: [],
    headers_bidders: [
      // {
      //   text: "Bidder #",
      //   align: "start",
      //   filterable: true,
      //   value: "id",
      // },
      {
        text: "Bidder Name",
        align: "start",
        filterable: true,
        value: "name",
      },
      {
        text: "Company Name",
        value: "company.company_name",
      },
      {
        text: "Email",
        value: "email",
      },
      {
        text: "Phone",
        value: "phone",
      },
    ],

    headers: [
      {
        text: "Item Name",
        align: "start",
        filterable: true,
        value: "name",
      },
      {
        text: "Details",
        value: "description",
      },
      {
        text: "Bidder 1",
        value: "score.1",
      },
      {
        text: "Bidder 2",
        value: "score.2",
      },
      {
        text: "Bidder 3",
        value: "score.3",
      },
    ],
    results: [
      {
        text: "Applicant Name",
        align: "start",
        filterable: true,
        value: "applicant.name",
      },
      {
        text: "Company Name",
        value: "applicant.company.company_name",
      },
      {
        text: "Email",
        value: "applicant.email",
      },
      {
        text: "Phone",
        value: "applicant.phone",
      },
      {
        text: "Total",
        value: "total",
      },
    ],
    desserts: [],
    scores: [],
    totals: [],
    bidder_1: {},
    bidder_2: {},
    bidder_3: {},
    applicant1: false,
    applicant2: false,
    applicant3: false,
    editedIndex: -1,
    editedItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
      constractor_id: "",
      tender_id: "",
    },
    defaultItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
      constractor_id: "",
      tender_id: "",
    },
  }),

  components: {
    EvaluationReportHeader,
    VueHtml2pdf,
    MainStepper,
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
    user() {
      return this.$store.state.auth.user;
    },
    tender() {
      return this.$store.state.tender.selected;
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {
    this.initialize();
    this.getEvaluationScores();
    this.getReportReport();
  },

  methods: {
    awardAndContinueToApproval() {
      // Award tender
      this.$refs.form.validate();
      if (this.valid) {
        TenderService.awardTender(this.editedItem).then(
          (response) => {
            console.log(response);
            if (response.status == 200) {
              this.$store.dispatch("alert/success", response.data.message);
              this.award_dialog = false;
              this.goToApprovals();
            } else {
              this.$store.dispatch(
                "alert/error",
                "Invalid data, please check the form try again!"
                // response.response.statusText
              );
            }
          },
          (error) => {
            console.log(error);
            if (error.response.status == 422) {
              this.$store.dispatch("alert/error", error.response.data.message);
            }
            this.$store.dispatch("alert/error", error.response.data.message);
          }
        );
      }
    },
    getBidderName(item) {
      return `${item.name} - ${item.company.company_name} - (${item.phone})`;
    },
    goToApprovals() {
      this.$router.push("/authority-approvals");
    },
    async handleFileUpload(file, item, tender) {
      console.log("uploading...");
      let formData = {};
      formData["tender_id"] = tender.id;
      formData["applicant_id"] = this.user.id;
      formData["document_id"] = item.id;
      await this.getBase64(file).then((data) => (formData["content"] = data));
      this.file = null;
      TenderService.addApplicantFinancialDocument(formData).then(
        (response) => {
          if (response.status == 200) {
            this.$store.dispatch("alert/success", "Document uploaded!");
            this.getApplicantUploadedFinancialDocs();
            this.loading = false;
          } else {
            this.$store.dispatch("alert/error", response.message);
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          if (error.response.status == 422) {
            console.log(error.response.data.errors);
            this.message = error.response.data.message;
          } else {
            this.message =
              (error.response && error.response.data) ||
              error.message ||
              error.toString() ||
              "Internal Server Error";
          }
          this.$store.dispatch("alert/error", this.message);
          this.loading = false;
        }
      );
    },
    awardSupplier() {
      this.award_dialog = true;
      this.editedItem.id = this.tender.id;
      this.editedItem.tender_id = this.tender.id;
    },
    goToSupplierSelection() {
      this.$router.push("/supplier-selection");
    },
    goToEvaluationReports() {
      this.$router.push("/report");
    },
    generateReport() {
      var printable_content = document.getElementById("printable_content");
      var pdf = document.getElementById("printarea");
      pdf.innerHTML = printable_content.innerHTML;
      this.$refs.html2Pdf.generatePdf();
    },
    decimalDigits(value) {
      return value.toFixed(2);
    },
    getEvaluationScores() {
      return TenderService.getEvaluationScores(this.tender.id).then(
        (response) => {
          if (response.status == 200) {
            this.scores = response.data.data;
            this.loading = false;
          } else {
            this.loading = false;
            console.log(response.data.error);
          }
        },
        (error) => {
          console.log(error);
        }
      );
    },
    getReportReport() {
      return TenderService.getEvaluationReport(this.tender.id).then(
        (response) => {
          console.log(response.data.data);
          if (response.status == 200) {
            this.evaluation_report = response.data.data.data;
            this.evaluation_bidders = Object.values(response.data.data.bidders);
            console.log(this.evaluation_bidders);
          }
        },
        (error) => {
          console.log(error);
        }
      );
    },
    initialize() {
      this.desserts = [
        {
          name: "Evidence of financial resources (Bank statements, letters of credit etc)",
          location: "NOKYSL/AI/01/06/2020",
          type: "Sole Proprietor",
          marks: "10",
          bidder_1: "2",
          bidder_2: "1",
          bidder_3: "5",
        },
        {
          name: "Name, address and telephone of bank reference Provided and satisfactory None ",
          location: "NOKYSL/AI/01/06/2020",
          type: "Limited",
          marks: "20",
          bidder_1: "15",
          bidder_2: "19",
          bidder_3: "10",
        },
        {
          name: "Qualifications and experience of key personnel (Attach CVs and certificates)",
          location: "NOKSSL/BI/01/06/2020",
          type: "Private Limited Company",
          marks: "50",
          bidder_1: "10",
          bidder_2: "35",
          bidder_3: "13",
        },
        {
          name: "Competency of key operations and technical staff in charge of security.",
          location: "NOKSSL/ BI/01/06/2020",
          marks: "15",
          bidder_1: "3",
          bidder_2: "5",
          bidder_3: "6",
        },
        {
          name: " ",
          location: " ",
          type: "",
          marks: " ",
          bidder_1: " ",
          bidder_2: "",
          bidder_3: "",
        },
        {
          name: "TOTAL MARKS",
          marks: "76",
          bidder_1: "50",
          bidder_2: "55",
          bidder_3: "70",
        },
        {
          name: "TOTAL TECHNICAL SCORE",
          marks: "60",
          bidder_1: "80",
          bidder_2: "45",
          bidder_3: "78",
        },
        {
          name: "WEIGHTED SCORE (50%)",
          marks: "54",
          bidder_1: "60",
          bidder_2: "50",
          bidder_3: "32",
        },
        {
          name: "TOTAL SCORE",
          marks: "76",
          bidder_1: "85",
          bidder_2: "79",
          bidder_3: "80",
        },
      ];
    },

    editItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      const index = this.desserts.indexOf(item);
      confirm("Are you sure you want to delete this item?") &&
        this.desserts.splice(index, 1);
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.desserts[this.editedIndex], this.editedItem);
      } else {
        this.desserts.push(this.editedItem);
      }
      this.close();
    },
  },
};
</script>