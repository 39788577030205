<template>
  <div class="m-auto my-3 pt-10 text-center align-center " max-width="100%">
    <h2 class="error--text text--darken-1">TENDER EVALUATION REPORT</h2>
    <h4 class="error--text text--darken-1">TENDER NO. {{ tender.tender_no }}</h4>
    <v-card-text>
      <div class="caption">
        {{ tender.title }} (EVALUATION FOR THE PERIOD {{ tender.closing_date }} TO {{ tender.evaluation_end_date }})
      </div>
      <!-- <v-divider class="my-4"></v-divider> -->
    </v-card-text>
  </div>
</template>

<script>
export default {
  name: "EvaluationReportHeader",
  data: () => ({
    selection: 1,
  }),

  computed: {
    user() {
      return this.$store.state.auth.user;
    },
    tender() {
      return this.$store.state.tender.selected;
    },
  },

  methods: {
    reserve() {
    },
  },
};
</script>
